import { html } from "haunted";
import i18next from "i18next";
import { useState } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { LINKS, TODOSUMA_MINIMUM_POINTS } from "Services/constants";
import numberFormatter from "Services/numberFormatter";

export const useShadowDOM = false;
export const name = "ac-bancoe-bar";

export type DcMembershipType = "None" | "Standard" | "Group";

export interface Props {
	bancoEstadoCategory: number;
	dcMembershipType: DcMembershipType;
	freeSeats: number;
	isBarOpen: boolean;
	todoSumaPointsAmount: number;
	isTodoSumaOn: boolean;
}

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		bancoEstadoCategory: host.bancoEstadoCategory,
		dcMembershipType: host.dcMembershipType,
		freeSeats: host.freeSeats,
		isBarOpen: host.isBarOpen,
		todoSumaPointsAmount: host.todoSumaPointsAmount,
		isTodoSumaOn: host.isTodoSumaOn,
	};

	const formatPoints = (points: number): string => {
		return numberFormatter({
			amount: points,
			culture: "es-CL",
			leadingSign: false,
			currency: "CLP",
		});
	};

	const [isMobileNoMembershipTooltipOpen, setIsMobileNoMembershipTooltipOpen] = useState<boolean>(false);
	const [isMobileStandardMembershipTooltipOpen, setIsMobileStandardMembershipTooltipOpen] = useState<boolean>(false);
	const [isMobileGroupMembershipTooltipOpen, setIsMobileGroupMembershipTooltipOpen] = useState<boolean>(false);
	const [isMobileTodosumaTooltipOpen, setIsMobileTodosumaTooltipOpen] = useState<boolean>(false);

	const enoughPointsTooltip = html`
		<div class="beb-tooltip ${props.bancoEstadoCategory === 6 ? "bg-be-orange" : "bg-be-gray-2"}">
			<div class="beb-tooltip-close-btn" @click=${() => setIsMobileTodosumaTooltipOpen(false)}>&times;</div>
			<div class="font-semibold">${i18next.t("Canjeando en JetSMART tus Puntos")}</div>
			<div>
				${i18next.t("TodoSuma valen un")} <span class="bett-todosuma-emphasis">${i18next.t("25%")}</span> más
			</div>
			<div class="mt-4 bett-xs text-left pl-2">${i18next.t("Ejemplo:")}</div>
			<div class="bett-xs">
				${i18next.t("En otros comercios tienes {{todosumaPoints}} puntos", {
					todosumaPoints: formatPoints(props.todoSumaPointsAmount),
				})}
			</div>
			<div class="mt-1 bett-xs font-semibold">${i18next.t("En JetSMART tienes")}</div>
			<div class="bett-points-example">
				${i18next.t("{{todosumaPoints}} puntos*1.25", {
					todosumaPoints: formatPoints(props.todoSumaPointsAmount),
				})}
				<span class="font-black">= ${formatPoints(props.todoSumaPointsAmount * 1.25)}</span>
			</div>
			<div class="mt-4">
				<a class="be-tooltip-btn" href=${LINKS.TodoSuma} target="_blank">
					${i18next.t("Más información")}
					<i class="jsh-icon jsh-circle-chevron-right"></i>
				</a>
			</div>
		</div>
	`;

	const notEnoughPointsTooltip = html`
		<div class="beb-tooltip ${props.bancoEstadoCategory === 6 ? "bg-be-orange" : "bg-be-gray-2"}">
			<div class="beb-tooltip-close-btn" @click=${() => setIsMobileTodosumaTooltipOpen(false)}>&times;</div>
			<div class="font-semibold">
				${i18next.t("¡Te falta muy poco para que puedas canjear tus Puntos TodoSuma!")}
			</div>
			<div>${i18next.t("*Mínimo de canje 3.000 Puntos.")}</div>
			<div class="mt-4">
				${i18next.t("Compra ahora con tu Tarjeta")}
				<span class="bett-emphasis">${i18next.t("SMART")}</span>
			</div>
			<div>
				${props.bancoEstadoCategory === 6
					? html`<span class="bett-emphasis"> ${i18next.t("y acumula un 50%")}</span>`
					: html`<span class="bett-emphasis"> ${i18next.t("y acumula un 20%")}</span>`}
				${i18next.t("más que en otros comercios.")}
			</div>
			<div class="mt-4">
				<a class="be-tooltip-btn" href=${LINKS.TodoSuma} target="_blank">
					${i18next.t("Más información")}
					<i class="jsh-icon jsh-circle-chevron-right"></i>
				</a>
			</div>
		</div>
	`;

	const noMembershipContent = html`
		<div class="beb-tooltip bg-be-cyan">
			<div class="beb-tooltip-close-btn" @click=${() => setIsMobileNoMembershipTooltipOpen(false)}>&times;</div>
			<div class="bebtt-membership-info">
				<div class="text-center">
					${i18next.t("¿Aún no eres parte del")}
					<br />
					<span>${i18next.t("Club de Descuentos")}</span> ${i18next.t("de")}
					<span>${i18next.t("JetSMART")}</span>?
				</div>
				<div class="mt-4 text-left">
					${i18next.t("Aprovecha tu precio preferente con tu")}
					<br />
					<span>${i18next.t("Tarjeta SMART")}</span> ${i18next.t("y obtén éstos beneficios")}:
				</div>
				<div class="bebtt-list-plus mt-4 text-left">
					<i class="js-icon-be2 js-be2-circle-plus"></i>
					${i18next.t("Ahorra hasta")}
					<span class="emphasis">${i18next.t("$5.000")}</span>
					${i18next.t("por tramo para tarifas sobre $15.000.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-plus"></i>
					${i18next.t("Ahorra")}
					<span class="emphasis">${i18next.t("$1.000")}</span>
					${i18next.t("por maleta por cada tramo de tu viaje.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-plus"></i>
					${i18next.t("Descuentos aplican para ti, y uno o 5 acompañantes, según membresía.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-plus"></i>
					${i18next.t("Acceso a ofertas exclusivas del")}
					<span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
				</div>
			</div>
			<div class="mt-4">
				<a class="be-tooltip-btn" href=${LINKS.DC} target="_blank">
					${i18next.t("¡Lo quiero!")}
					<i class="jsh-icon jsh-circle-chevron-right"></i>
				</a>
			</div>
		</div>
	`;

	const standardMembershipContent = html`
		<div class="beb-tooltip bg-be-cyan">
			<div class="beb-tooltip-close-btn" @click=${() => setIsMobileStandardMembershipTooltipOpen(false)}>
				&times;
			</div>
			<div class="bebtt-membership-info">
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Ahorra hasta")}
					<span class="emphasis">${i18next.t("$5.000")}</span>
					${i18next.t("por tramo para tarifas sobre $15.000.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Ahorra")}
					<span class="emphasis">${i18next.t("$1.000")}</span>
					${i18next.t("por maleta por cada tramo de tu viaje.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Descuentos aplican para ti, y un acompañante.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Acceso a ofertas exclusivas del")}
					<span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
				</div>
			</div>
		</div>
	`;

	const groupMembershipContent = html`
		<div class="beb-tooltip bg-be-cyan">
			<div class="beb-tooltip-close-btn" @click=${() => setIsMobileGroupMembershipTooltipOpen(false)}>
				&times;
			</div>
			<div class="bebtt-membership-info">
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Ahorra hasta")}
					<span class="emphasis">${i18next.t("$5.000")}</span>
					${i18next.t("por tramo para tarifas sobre $15.000.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Ahorra")}
					<span class="emphasis">${i18next.t("$1.000")}</span>
					${i18next.t("por maleta por cada tramo de tu viaje.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Descuentos aplican para ti, y hasta 5 acompañantes.")}
				</div>
				<div class="bebtt-list-plus mt-2 text-left">
					<i class="js-icon-be2 js-be2-circle-tick"></i>
					${i18next.t("Acceso a ofertas exclusivas del")}
					<span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
				</div>
			</div>
		</div>
	`;

	const noMembershipSection = html`
		<div class="beb-section beb-section-3">
			<i class="beb-section-icon js-icon-be2 js-be2-money"></i>
			${i18next.t("Precio Preferente")}
			<span>${i18next.t("Club de Descuentos")}</span>
			<i
				class="js-icon js-flight-help beb-tooltip-opener"
				@click=${() => setIsMobileNoMembershipTooltipOpen(true)}
				>${noMembershipContent}</i
			>
		</div>
	`;

	const standardMembershipSection = html`
		<div class="beb-section beb-section-3">
			<i class="beb-section-icon js-icon-be2 js-be2-money"></i>
			${i18next.t("Membresía Estándar")}
			<span>${i18next.t("Club de Descuentos")}</span>
			<img class="beb-section-tick" src="/images/banco-estado/bar-tick-color.png" />
			<i
				class="js-icon js-flight-help beb-tooltip-opener"
				@click=${() => setIsMobileStandardMembershipTooltipOpen(true)}
			>
				${standardMembershipContent}
			</i>
		</div>
	`;

	const groupMembershipSection = html`
		<div class="beb-section beb-section-3">
			<i class="beb-section-icon js-icon-be2 js-be2-money"></i>
			${i18next.t("Membresía Grupal")}
			<span>${i18next.t("Club de Descuentos")}</span>
			<img class="beb-section-tick" src="/images/banco-estado/bar-tick-color.png" />
			<i
				class="js-icon js-flight-help beb-tooltip-opener"
				@click=${() => setIsMobileGroupMembershipTooltipOpen(true)}
			>
				${groupMembershipContent}
			</i>
		</div>
	`;

	const seat40Section = html`
		<div class="beb-section beb-section-4">
			<i class="beb-section-icon js-icon-be2 js-be2-seat"></i>
			<div class="hidden-sm">${i18next.t("Desde")} <span>${i18next.t("40% descuento")}</span></div>
			<div class="hidden-sm"><span>${i18next.t("asientos")}</span> ${i18next.t("Zona BancoEstado")}</div>
		</div>
	`;

	const freeSeatsSection = html`
		<div class="beb-section beb-section-4">
			<i
				class="beb-section-icon js-icon-be2 js-be2-star${props.freeSeats <= 4
					? `-${props.freeSeats}`
					: "s-plus"}"
			></i>
			<div class="hidden-sm">
				<span>${i18next.t("Tienes")} ${props.freeSeats}</span>
			</div>
			<div class="hidden-sm">
				${i18next.t("asiento{{plural}} gratis", { plural: Number(props.freeSeats) > 1 ? "s" : "" })}
			</div>
		</div>
	`;

	const todoSumaSection = () =>
		props.isTodoSumaOn
			? html`<div class="beb-section beb-section-2">
						<div>
							${i18next.t("¡En")}
							<span class="bebs2-1">${i18next.t("JetSMART")}</span> ${i18next.t("tus")}
						</div>
						<div>${i18next.t("puntos")} <span class="bebs2-2">${i18next.t("TodoSuma")}</span></div>
						<div>${i18next.t("valen más!")}</div>
						<i
							class="js-icon js-flight-help beb-tooltip-opener"
							@click=${() => setIsMobileTodosumaTooltipOpen(true)}
						>
							${props.todoSumaPointsAmount < TODOSUMA_MINIMUM_POINTS
								? notEnoughPointsTooltip
								: enoughPointsTooltip}
						</i>
					</div>
					<div class="beb-divider"></div>`
			: "";

	return props.bancoEstadoCategory === 5 || props.bancoEstadoCategory === 6
		? html`
				<div
					class="be-bar ${props.bancoEstadoCategory === 6 ? "bg-be-gray" : "bg-be-orange"} ${props.isBarOpen
						? ""
						: "closed"}"
				>
					<div class="be-bar-container">
						<img src="/images/banco-estado/topbar-logo-2.svg" />
						<div class="beb-section beb-section-1">
							${i18next.t("Tus beneficios")}
							<span>${i18next.t("Tarjeta")}</span>
							<img
								src="/images/banco-estado/logo-smart-${props.bancoEstadoCategory === 6
									? "plus-bg-gray"
									: "bg-orange"}.png"
							/>
						</div>
						<div class="beb-divider"></div>
						${todoSumaSection()} ${props.dcMembershipType === "None" ? noMembershipSection : ""}
						${props.dcMembershipType === "Standard" ? standardMembershipSection : ""}
						${props.dcMembershipType === "Group" ? groupMembershipSection : ""}
						<div class="beb-divider"></div>
						${props.freeSeats === 0 || props.bancoEstadoCategory === 5 ? seat40Section : freeSeatsSection}
						<div class="beb-divider"></div>
						<div class="beb-section beb-section-6">
							<i class="beb-section-icon js-icon-be2 js-be2-priority"></i>
							<div class="hidden-sm">${i18next.t("Embarque")}</div>
							<div class="hidden-sm"><span>${i18next.t("Prioritario")}</span></div>
						</div>
						<div class="beb-divider"></div>
						<div class="beb-section beb-section-5">
							<i class="beb-section-icon js-icon-be2 js-be2-percent"></i>
							<div class="hidden-sm"><span>${i18next.t("6 o 12 cuotas")}</span></div>
							<div class="hidden-sm">${i18next.t("sin interés")}</div>
						</div>
					</div>
				</div>
				<div class="be-bar-mobile-tooltip ${isMobileNoMembershipTooltipOpen ? "open" : ""}">
					<div class="dg-be-modal"></div>
					${noMembershipContent}
				</div>
				<div class="be-bar-mobile-tooltip ${isMobileStandardMembershipTooltipOpen ? "open" : ""}">
					<div class="dg-be-modal"></div>
					${standardMembershipContent}
				</div>
				<div class="be-bar-mobile-tooltip ${isMobileGroupMembershipTooltipOpen ? "open" : ""}">
					<div class="dg-be-modal"></div>
					${groupMembershipContent}
				</div>
				<div class="be-bar-mobile-tooltip ${isMobileTodosumaTooltipOpen ? "open" : ""}">
					<div class="dg-be-modal"></div>
					${props.todoSumaPointsAmount < TODOSUMA_MINIMUM_POINTS
						? notEnoughPointsTooltip
						: enoughPointsTooltip}
				</div>
		  `
		: html``;
};
