import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { useEffect, useState } from "Shared/haunted/CustomHooks";
import { ref } from "Components/directives/ref";
import { html, useRef } from "haunted";
import i18next from "i18next";
import {
	MIN_TEEN_AGE,
	MIN_ADULT_AGE,
	MIN_PAX_NUMBER_FOR_GROUP_BOOKING,
	COLOMBIAN_CULTURE_CODE,
} from "Services/constants";
import { JetSmartEvent } from "Services/eventbus/JetSmartEvent";
import { tealiumLogPaxNumbers } from "ComponentHelpers/SearchboxTealiumHelpers";
import { usePane } from "../opening-pane/usePane";
import { CLASS_NAMES } from "Services/ClassNames";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { usePax } from "./usePax";
import classNames from "classnames";

export interface Props {
	culture: string;
	isFlightDomesticColombia: boolean;
}

export interface PaxViewModel {
	adults: number;
	children: number;
	infants: number;
	teens: number;
}

export const usePaxSelector = (props: Props) => {
	const pane = usePane();

	const paxRoot = useRef<HTMLDivElement>(undefined);
	const paxPane = useRef<HTMLDivElement>(undefined);

	const [vm, setVm] = useState<PaxViewModel>({ adults: 1, teens: 0, children: 0, infants: 0 });
	const [showTeenInfantRemoveWarning, setShowTeenInfantRemoveWarning] = useState<boolean>(false);
	const [showGroupWarning, setShowGroupWarning] = useState<boolean>(false);
	const [isValidated, setIsValidated] = useState<boolean>(false);

	const maxChildrenAmount = (model: PaxViewModel) => {
		if (model.adults > 0) return Number.POSITIVE_INFINITY;

		const retVal = model.teens - model.infants;

		return retVal > 0 ? retVal : 0;
	};

	const maxInfantAmount = (model: PaxViewModel) => {
		if (model.adults > 0) return model.adults + model.teens;

		const retVal = model.teens - model.children;

		return retVal > 0 ? retVal : 0;
	};

	const adults = usePax({
		ageNote: `(${MIN_ADULT_AGE} ${i18next.t("search-box-adults-min-age")})`,
		amount: vm.adults,
		amountTestId: TestIdDictionary.Pax.AdultAmount,
		decreaseTestId: TestIdDictionary.Pax.RemoveAdult,
		increaseTestId: TestIdDictionary.Pax.AddAdult,
		isIncreaseDisabled: false,
		label: i18next.t("search-box-adults"),
		onDecrease: () => decreasePax("adults"),
		onIncrease: () => increasePax("adults"),
	});

	const teens = usePax({
		ageNote: `(${MIN_TEEN_AGE} - ${MIN_ADULT_AGE - 1} ${i18next.t("search-box-children-age")})`,
		amount: vm.teens,
		amountTestId: TestIdDictionary.Pax.TeenAmount,
		decreaseTestId: TestIdDictionary.Pax.RemoveTeen,
		increaseTestId: TestIdDictionary.Pax.AddTeen,
		isIncreaseDisabled: false,
		label: i18next.t("search-box-teens"),
		onDecrease: () => decreasePax("teens"),
		onIncrease: () => increasePax("teens"),
	});

	const children = usePax({
		ageNote: `(2 - ${MIN_TEEN_AGE - 1} ${i18next.t("search-box-children-age")})`,
		amount: vm.children,
		amountTestId: TestIdDictionary.Pax.ChildAmount,
		decreaseTestId: TestIdDictionary.Pax.RemoveChild,
		increaseTestId: TestIdDictionary.Pax.AddChild,
		isIncreaseDisabled: vm.children >= maxChildrenAmount(vm),
		label: i18next.t("search-box-children"),
		onDecrease: () => decreasePax("children"),
		onIncrease: () => increasePax("children"),
	});

	const infants = usePax({
		ageNote: i18next.t("search-box-infants-age"),
		amount: vm.infants,
		amountTestId: TestIdDictionary.Pax.InfantAmount,
		decreaseTestId: TestIdDictionary.Pax.RemoveInfant,
		increaseTestId: TestIdDictionary.Pax.AddInfant,
		isIncreaseDisabled: vm.infants >= maxInfantAmount(vm),
		label: i18next.t("search-box-infants"),
		onDecrease: () => decreasePax("infants"),
		onIncrease: () => increasePax("infants"),
	});

	// Helpers

	const init = () => {
		window.setTimeout(() => tealiumLogPaxNumbers(vm), 0);
	};

	const showChildrenAgeWarningDomColombia = () =>
		vm.children > 0 &&
		props.isFlightDomesticColombia &&
		props.culture.toLowerCase() === COLOMBIAN_CULTURE_CODE &&
		!showGroupWarning &&
		!showTeenInfantRemoveWarning;

	const onClickOutsidePax = (e: MouseEvent) => {
		const path = e.composedPath && e.composedPath();

		const isClickInside = path.indexOf(paxRoot.current) > -1 || path.indexOf(paxPane.current) > -1;

		if (!isClickInside && pane.mode === "passengers") {
			pane.set("none");
			setShowGroupWarning(false);
		}
	};

	const showMinorOrInfantAgeWarning = () => vm.children > 0 || vm.infants > 0;

	const adjustChildrenAndInfantAmountsSafely = (model: PaxViewModel): PaxViewModel => {
		const newVm = { ...model };

		if (newVm.infants > maxInfantAmount(newVm)) newVm.infants = maxInfantAmount(newVm);

		if (newVm.children > maxChildrenAmount(newVm)) newVm.children = maxChildrenAmount(newVm);

		return newVm;
	};

	const formattedAmount = (model: PaxViewModel) => {
		const displayedValue: string[] = [];

		if (model.adults > 0) {
			displayedValue.push(`${model.adults} ${model.adults === 1 ? i18next.t("adult") : i18next.t("adults")}`);
		}

		if (model.teens > 0) {
			displayedValue.push(`${model.teens} ${model.teens === 1 ? i18next.t("teen") : i18next.t("teens")}`);
		}

		if (model.children > 0) {
			displayedValue.push(
				`${model.children} ${model.children === 1 ? i18next.t("child") : i18next.t("children")}`
			);
		}

		if (model.infants > 0) {
			displayedValue.push(`${model.infants} ${model.infants === 1 ? i18next.t("infant") : i18next.t("infants")}`);
		}

		return displayedValue.length > 0 ? displayedValue.join(", ") : "";
	};

	const validate = () => {
		setIsValidated(true);

		return (
			vm.adults + vm.teens >= 1 &&
			vm.children >= 0 &&
			vm.infants >= 0 &&
			vm.adults + vm.teens + vm.children <= MIN_PAX_NUMBER_FOR_GROUP_BOOKING - 1 &&
			vm.infants <= vm.adults + vm.teens &&
			(vm.adults > 0 || vm.children + vm.infants <= vm.teens)
		);
	};

	const increasePax = (key: keyof PaxViewModel) => {
		const newVm = { ...vm };

		const canAddMorePax =
			key === "infants"
				? newVm.infants < maxInfantAmount(newVm)
				: newVm.adults + newVm.teens + newVm.children < MIN_PAX_NUMBER_FOR_GROUP_BOOKING - 1;

		if (canAddMorePax) {
			newVm[key] = newVm[key] + 1;
		}

		setShowTeenInfantRemoveWarning(false);
		setShowGroupWarning(key !== "infants" && !canAddMorePax);

		setVm(newVm);
	};

	const decreasePax = (key: keyof PaxViewModel) => {
		let newVm = { ...vm };

		newVm[key] = newVm[key] - 1;
		newVm = adjustChildrenAndInfantAmountsSafely(newVm);

		setShowTeenInfantRemoveWarning(
			key === "teens" &&
				newVm.adults + newVm.teens + newVm.children + newVm.infants > 0 &&
				vm.infants > maxInfantAmount(newVm)
		);

		setShowGroupWarning(false);

		setVm(newVm);
	};

	// Event listeners

	const handleClose = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		pane.set("none");
	};

	const handleClick = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		(e.target as HTMLInputElement).blur();

		if (e.currentTarget instanceof HTMLElement) pane.set("passengers");
	};

	const updateOnSearchReload = (model: PaxViewModel) => setVm({ ...model });

	useEffect(() => {
		tealiumLogPaxNumbers(vm);
		window.eventBus.raiseEvent({
			name: JetSmartEvent.PassengersChanged,
			params: {
				id: "searchbox.passengers",
				event: "change",
				data: {
					adults: vm.adults,
					teens: vm.teens,
					children: vm.children,
					infants: vm.infants,
				},
			},
		});
	}, [vm.adults, vm.teens, vm.children, vm.infants]);

	useEffect(() => {
		if (pane.mode === "passengers") {
			const sub = PUB_SUBS.DocumentClicked.subscribe((e) => onClickOutsidePax(e.mouseEvent));
			return () => sub.unsubscribe();
		}
		return () => {};
	}, [pane.mode]);

	useEffect(init, []);

	// Templates

	const inputFieldTemplate = () => html`
		<div ref=${ref(paxRoot)} class="dg-quantity-wrapper" @click=${handleClick}>
			<input
				class=${classNames({
					[CLASS_NAMES.error]: isValidated && formattedAmount(vm).length === 0,
				})}
				placeholder=${i18next.t("search-box-passengers")}
				autocomplete="disabled"
				readonly
				required
				value=${formattedAmount(vm)}
				.value=${formattedAmount(vm)}
				data-test-id=${TestIdDictionary.Pax.Input}
			/>
		</div>
	`;
	const mobilePaneCloserTemplate = () => html`
		<div class="hidden-sm-up dg-quantity-header">
			<span @click=${handleClose}>&times;</span>
		</div>
	`;

	const paneCloserTemplate = () => html`
		<div class="dg-btn-container">
			<button class="dg-button dg-pax-btn" @click=${handleClose} data-test-id=${TestIdDictionary.Pax.CloseButton}>
				OK
			</button>
		</div>
	`;

	const groupWarningTemplate = () => {
		const [language, country] = props.culture.toLowerCase().split("-");
		const groupUrl = `/${country}/${language}/reservas-en-grupo/`;

		return showGroupWarning
			? html`
					<div class="dg-pax-quantity-message">
						${i18next.t("search-box-group-warning-1")}
						<a href=${groupUrl}>${i18next.t("search-box-group-warning-2")}</a>.
					</div>
			  `
			: "";
	};

	const minorOrInfantAgeWarningTemplate = () =>
		showMinorOrInfantAgeWarning()
			? html`<div class="dg-pax-warning">${i18next.t("search-box-minor-age-warning")}</div>`
			: "";

	const showTeenInfantRemoveWarningTemplate = () =>
		showTeenInfantRemoveWarning
			? html`<div class="dg-pax-warning">${i18next.t("search-box-infant-warning")}</div>`
			: "";

	const childrenAgeWarningDomColombiaTemplate = () =>
		showChildrenAgeWarningDomColombia()
			? html`<div class="dg-pax-warning">${i18next.t("searchbox-domcom-age-warning")}</div>`
			: "";

	const paneTemplate = () => html`
		<div
			ref=${ref(paxPane)}
			data-test-id=${TestIdDictionary.Pax.Container}
			class=${classNames("dg-quantity-dropdown", {
				open: pane.mode === "passengers",
			})}
		>
			${mobilePaneCloserTemplate()} ${adults.htmlTemplate()} ${teens.htmlTemplate()} ${children.htmlTemplate()}
			${infants.htmlTemplate()} ${groupWarningTemplate()} ${paneCloserTemplate()}
			${minorOrInfantAgeWarningTemplate()} ${showTeenInfantRemoveWarningTemplate()}
			${childrenAgeWarningDomColombiaTemplate()}
		</div>
	`;

	const htmlTemplate = () => html` ${inputFieldTemplate()} ${paneTemplate()} `;

	return { passengers: vm, htmlTemplate, updateOnSearchReload, validate };
};
